import {
  AddBusiness,
  Animation,
  ArrowRightAlt,
  Business,
  ComputerTwoTone,
  GraphicEq,
  PendingActionsSharp,
  PhoneAndroid,
  SearchSharp,
  SettingsSuggest,
  SupportSharp,
} from "@mui/icons-material";
import React from "react";

function Blockers() {
  return (
    <div className=" sm:gap-2 xs:gap-2 grid grid-rows-2 md:grid-rows-2 sm:grid-rows-none xs:grid-rows-none  grid-cols-none md:grid-cols-none sm:grid-cols-2 xs:grid-cols-2 grid-flow-col md:grid-flow-col sm:grid-flow-row xs:grid-flow-row  place-content-end md:place-content-end sm:place-content-center xs:place-content-center mt-12">
      <div className="bg-[#222] pb-4  Transition  w-20 md:h-44 h-80 sm:h-40 xs:h-40 md:w-48 sm:w-full xs:w-full rounded-lg flex flex-col justify-center items-center">
        <PhoneAndroid className="secondary text-7xl important Border p-2 rounded-full" />
        <h3 className="text-white text-center text-[17px] w-[80%] ">
          Comprehensive Keyword Research
        </h3>
      </div>
      <div className="bg-[#222] pb-4  Transition  w-20 md:h-44 h-80 sm:h-40 xs:h-40 md:w-48 sm:w-full xs:w-full rounded-lg flex flex-col justify-center items-center">
        <SettingsSuggest className="secondary text-7xl important p-2 Border rounded-full" />
        <h3 className="text-white text-[17px] text-center  w-[80%]">
          On-Page Optimization
        </h3>
      </div>
      <div className="bg-[#222] pb-4  Transition  w-20 md:h-44 h-80 sm:h-40 xs:h-40 md:w-48 sm:w-full xs:w-full rounded-lg flex flex-col justify-center items-center">
        <Animation className="secondary text-7xl important Border p-2 rounded-full" />
        <h3 className="text-white text-[17px] text-center  w-[80%]">
          Relevant Content
        </h3>
      </div>

      <div className="bg-[#222] pb-4  Transition  w-20 md:h-44 h-80 sm:h-40 xs:h-40 md:w-48 sm:w-full xs:w-full rounded-lg flex flex-col justify-center items-center">
        <GraphicEq className="secondary text-7xl important Border p-2 rounded-full" />
        <h3 className="text-white text-[17px] text-center  w-[80%]">
          Link Building
        </h3>
      </div>
      <div className="bg-[#222] pb-4  Transition  w-20 md:h-44 h-80 sm:h-40 xs:h-40 md:w-48 sm:w-full xs:w-full rounded-lg flex flex-col justify-center items-center">
        <Business className="secondary text-7xl important Border p-2 rounded-full" />
        <h3 className="text-white text-[17px] text-center  w-[80%]">
          Ongoing Monitoring
        </h3>
      </div>
      <div className="bg-[#222] pb-4  Transition  w-20 md:h-44 h-80 sm:h-40 xs:h-40 md:w-48 sm:w-full xs:w-full rounded-lg flex flex-col justify-center items-center">
          <SupportSharp className="secondary text-7xl important Border p-2 rounded-full" />
        <h3 className="text-white text-[17px] text-center  w-[80%]">
          Transparent Reporting
        </h3>
      </div>
    </div>
  );
}

export default Blockers;

import React from "react";
import Landing from "./components/LandingFace";
import Experty from "./components/Experty";
import Blockers from "./components/Blockers";
import Features from "./components/Features";
import Whyus from "./components/WhyUs";
import Whatweare from "./components/Whatweare";
import PicSpacer from "../../Home/Components/PicSpacer";
import Ready from "./components/Ready";
import Portfolio from "./components/portfoliio"
import { Helmet } from "react-helmet";


function index() {
  return (
    <div>
      <Helmet>
        <title>SEO : search engine optimization</title>
        <meta
          name="description"
          content="Our SEO services are designed to improve your website's visibility in search engine results, driving organic traffic and boosting your online presence."
        />
        <link rel="canonical" href="https://fnevol.com/SEO" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="text" />
        <meta
          property="og:title"
          content="seo : search engine optimization : fast search : business online"
        />
        <meta
          property="og:description"
          content="Our SEO services are designed to improve your website's visibility in search engine results, driving organic traffic and boosting your online presence."
        />
        <meta property="og:url" content="https://fnevol.com/SEO" />
        {/* End Facebook tags */}
      </Helmet>
  
      <Landing />
      <Whatweare />
      <Whyus />
      <Features />
      {/* <Portfolio /> */}
      <Ready />
      <PicSpacer />
    </div>
  );
}

export default index;

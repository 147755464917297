import { Check, CheckCircle } from "@mui/icons-material";
import React from "react";

function Grids() {
  return (
    <div className="flex flex-row mt-8 justify-between flex-wrap gap-4">
      <div className="bg-[#222] w-[300px] md:w-[300px] sm:w-[100%] xs:w-[100%] rounded-lg h-full flex flex-row justify-center items-center flex-col  ">
        <div className="bg-[#111] flex justify-center items-center flex-col w-full  h-24  ">
          <h3 className="text-[30px] font-black primary text-center text-white">
            Light Website
          </h3>
          <p className="text-[14px] text-center Whitext font-normal ">
            For Small Businesses and Individuals
          </p>
        </div>
        <h3 className="darkshadow text-[14px] text-center mt-6 text-white ">
          Ksh <span className="text-[37px] secondary font-black"> 26,390 </span>{" "}
        </h3>
        <div className="flex flex-col justify-start text-white items-start w-[86%] ml-[7%]">
          <div className="flex flex-row items-center justify-start ">
            <CheckCircle />
            <p className="text-[16px]  mt-6 font-normal p-4">
              Small Business & Personal Website
            </p>
          </div>
          <div className="flex flex-row items-center justify-start ">
            <CheckCircle />
            <p className="text-[16px]   font-normal p-4">Basic Features</p>
          </div>

          <div className="flex flex-row items-center justify-start ">
            <CheckCircle />
            <p className="text-[16px]  font-normal p-4">
              Perfect & Unique Website
            </p>
          </div>

          <div className="flex flex-row items-center justify-start ">
            <CheckCircle />
            <p className="text-[16px]  font-normal p-4">Basic Functionality</p>
          </div>
          <div className="flex flex-row items-center justify-start ">
            <CheckCircle />
            <p className="text-[16px]  font-normal p-4">13 Pages Maximum</p>
          </div>
        </div>
        <div className=" w-full  flex flex-col items-center justify-center">
          <button className="py-2 mt-4 text-white text-xl w-36 h-12 rounded-full border border-orange-500">
            Get Started
          </button>
          <p className="text-[14px] Whitext text-center mb-8 text-lg font-light p-4">
            Ideal for new businesses and personal websites.
          </p>
        </div>
      </div>

      {/* Grid @2 */}

      <div className="bg-[#222] w-[300px] md:w-[300px] sm:w-[100%] xs:w-[100%] rounded-lg h-full flex flex-row justify-center items-center flex-col  ">
        <div className="bg-[#111] flex justify-center items-center flex-col w-full  h-24  ">
          <h3 className="text-[30px] font-black primary text-center text-white">
            Standard Website
          </h3>
          <p className="text-[14px] text-center Whitext font-normal ">
            {" "}
            For Established Business
          </p>
        </div>
        <h3 className="text-[14px] text-center text-white primary mt-6">
          Ksh <span className="text-[37px] secondary font-black"> 48,560 </span>{" "}
        </h3>
        <div className="flex flex-col justify-start text-white items-start w-[86%] ml-[7%]">


    

          <div className="flex flex-row items-center justify-start ">
            <CheckCircle />
            <p className="text-[16px]  font-normal p-4">
              {" "}
              Medium Sized Website
            </p>
          </div>
          <div className="flex flex-row items-center justify-start ">
            <CheckCircle />
            <p className="text-[16px]  font-normal p-4">
              {" "}
              Advanced Features
            </p>
          </div>
          <div className="flex flex-row items-center justify-start ">
            <CheckCircle />
            <p className="text-[16px]  font-normal p-4">
              {" "}
              Customized and Attractive Website
            </p>
          </div>
          <div className="flex flex-row items-center justify-start ">
            <CheckCircle />
            <p className="text-[16px]  font-normal p-4">
              {" "}
              Advanced Functionality
            </p>
          </div>
          <div className="flex flex-row items-center justify-start ">
            <CheckCircle />
            <p className="text-[16px]  font-normal p-4">
              {" "}
              27 Pages Maximum
            </p>
          </div>
        </div>
        <div className=" w-full  flex flex-col items-center justify-center">
          <button className="py-2 mt-4 text-white text-xl w-36 h-12 rounded-full border border-orange-500">
            Get Started
          </button>
          <p className="text-[14px] Whitext text-center mb-8 text-lg font-light p-4">
            Ideal for big businesses.
          </p>
        </div>
      </div>

      {/* Grid @3 */}

      <div className="bg-[#222] w-[300px] md:w-[300px] sm:w-[100%] xs:w-[100%] rounded-lg h-full flex flex-row justify-center items-center flex-col  ">
        <div className="bg-[#111] flex justify-center items-center flex-col w-full  h-24  ">
          <h3 className="text-[30px] font-black primary text-center text-white">
            Premium Website
          </h3>
          <p className="text-[14px] text-center Whitext font-normal ">
            {" "}
            For Large Businesses and Organizations
          </p>
        </div>
        <h3 className="text-[37px] font-black text-center mt-6 secondary">
          Request Qoute
        </h3>
        <div className="flex flex-col justify-start text-white items-start w-[86%] ml-[7%]">
        <div className="flex flex-row items-center justify-start ">
            <CheckCircle />
            <p className="text-[16px]  font-normal p-4">
              {" "}
              Corporates & Large Business Website
            </p>
          </div>
          <div className="flex flex-row items-center justify-start ">
            <CheckCircle />
            <p className="text-[16px]  font-normal p-4">
              {" "}
              Super Integration
            </p>
          </div>
          <div className="flex flex-row items-center justify-start ">
            <CheckCircle />
            <p className="text-[16px]  font-normal p-4">
              {" "}
              Perfect and Attractive Website
            </p>
          </div>
          <div className="flex flex-row items-center justify-start ">
            <CheckCircle />
            <p className="text-[16px]  font-normal p-4">
              {" "}
              Pro-Level Functionality
            </p>
          </div>
          <div className="flex flex-row items-center justify-start ">
            <CheckCircle />
            <p className="text-[16px]  font-normal p-4"> Unlimited Pages</p>
          </div>
        </div>
        <div className=" w-full  flex flex-col items-center justify-center">
          <button className="py-2 mt-4 text-white text-xl w-36 h-12 rounded-full border border-orange-500">
            Get Started
          </button>
          <p className="text-[14px] Whitext text-center mb-8 text-lg font-light p-4">
            Ideal for ecommerce & super Corporates.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Grids;

import React from "react";
import Home from "../../../Assets/Images/Home/HomeHead.png";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";

function LandingFace() {
  return (
    <div className="HomePageLanding  h-[100vh] w-full relative z-10">
        <div className=" flex flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col justify-center items-center h-[100vh]   md:h-[100vh] sm:h-[100vh] xs:h-[100vh] w-full  zindex-8">
        <div className=" basis-1/2 md:basis-1/2 sm:basis-3/4 xs:basis-3/4 grid border-tr-1 border-bl-1 rounded-full border-br-1 shadow-gray-300 shadow-lg  content-end p-8 md:p-8 sm:p-4 xs:p-4">
          <img
            src={Home}
            className="w-[100%] lg:w-[100%] rounded-full  md:w-[100vw] "
          />
        </div>
        <div className="mt-20 sm:mt-0 xs:mt-0 basis-1/2 md:basis-1/2 sm:basis-1/4 xs:basis-1/4  p-8 md:p-8 sm:p-4 xs:p-4 flex justify-center  flex-col items-start mt-[30vh] w-full h-[100vh] ">
          <h3 className="backdrop-blur-sm  text-[#333] font-black text-[50px] md:text-5xl sm:text-3xl xs:text-3xl w-[70%] md:w-[90%] xs:w-[90%] sm:w-[90%]">
            We Deliver_ 
            <br className="block md:block sm:hidden xs:hidden " />
                 Innovative <br className="hidden md:hidden  sm:hidden xs:hidden " />
            Solutions <br className="block md:hidden sm:hidden xs:hidden " />
            Tailor-Made For You
          </h3>
          <p className="backdrop-blur-sm  block md:block sm:hidden xs:hidden  w-[70%] md:w-[90%] xs:w-[90%] sm:w-[90%] text-[#111] leading-1 pt-8 sm:pt-4 xs:pt-4 text-lg md:text-lg sm:text-lg xs:text-lg ">
          With extensive experience in our respective services fields. We stay up-to-date with the latest industry trends and technologies to deliver top-notch solutions.
          </p>
          <button className="mt-8 sm:mt-4 xs:mt-4 Whiteshadow text-xl  Primary text-white w-40 text-center rounded-tr-full rounded-br-full rounded-bl-full h-12">
            Check More
          </button>

          {/* Blocks and icons */}
          <div className="mt-8 sm:mt-4 xs:mt-4  flex-row gap-3 flex md:flex sm:hidden xs:hidden    px-4 py-2 rounded-full whiteshadow">
            <div className="w-12  h-12 bg-white md:bg-white sm:bg-black xs:bg-black darkshadow flex justify-center items-center rounded-full">
              <Instagram className="important font-xl secondary" />
            </div>
            <div className="w-12 h-12 bg-white md:bg-white sm:bg-black  xs:bg-black darkshadow flex justify-center items-center rounded-full">
              <Facebook className="important font-xl secondary" />
            </div>
            <div className="w-12 h-12 bg-white md:bg-white darkshadow sm:bg-black xs:bg-black flex justify-center items-center rounded-full">
              <LinkedIn className="important font-xl secondary" />
            </div>
            <div className="w-12 h-12 bg-white md:bg-white darkshadow sm:bg-black xs:bg-black flex justify-center items-center rounded-full">
              <Twitter className="important font-xl secondary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingFace;

import React from "react";
import { AccessAlarm, ThreeDRotation } from "@mui/icons-material";
import Blockers from "./Blockers";

function Features() {
  return (
    <div className="px-16 pb-8 md:px-16 BackBlocksWeb sm:px-4 xs:px-4  GrayMorphism pt-8 ">
     
      <div>
        <Blockers/>
      </div>
    </div>
  );
}

export default Features;

import React from "react";
import { Link } from "react-router-dom";

function Ready() {
  return (
    <div className="flex flex-col my-12 justify-center items-center px-16 md:px-16 sm:px-4 xs:px-4 w-[70%] ml-[15%] md:ml-[15%]  sm:ml-[0%] xs:ml-[0%]    md:w-[70%] xs:w-[100%] sm: w-[100%] ">
      <h2 className="tracking-wider text-center text-4xl mt-4 font-black primary">
        Are You Ready to Kick Start?
      </h2>
      <p className="text-center text-lg">
        Don't let your website get lost in the vast digital landscape. Contact
        us today to discuss your SEO goals, and let our experts devise a
        customized strategy to elevate your online presence and drive
        sustainable business growth.
      </p>
      {/* Contact US Button */}
      <Link to="/Contact">
        <button className="py-2 mt-4  text-xl w-36 h-12 rounded-full border border-orange-500">
          Get Started
        </button>
      </Link>
    </div>
  );
}

export default Ready;

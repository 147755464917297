import React from "react";
import Grids from "./Grids";

function Websolutions() {
  return (
    <div className="px-16 pb-8 md:px-16 sm:px-8 xs:px-8  pt-8 PrimaryB ">
      <div className="flex flex-row justify-center items-center pt-8 ">
        <hr className="w-12 text-2xl " />
        <h3 className="text-xl px-4 sm:text-center xs:text-center w-[100%] tracking-wider secondary">
          We have the best for you
        </h3>

        <hr className="w-12 text-2xl" />
      </div>
      <h2 className="tracking-wider text-center text-4xl mt-4 font-black text-white">
        Our Web Design Solutions
      </h2>
      <div className="mt-8" >
        <Grids/>
      </div>
    </div>
  );
}

export default Websolutions;

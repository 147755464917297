import React from "react";
import AboutPic from "../../../Assets/Images/Contact/AboutUs.png"
import { Check, Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";

function LandingFace() {
  return (
    <div className="HomePageLandingAbout ">
     <div className=" py-24 lg:py-24  md:py-24  sm:py-14  xs:py-14  px-16 md:px-16 sm:px-4 xs:px-4  grid gap-4 grid-cols-2  lg:grid-cols-2  md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1   ">
      <div className=" Transition  w-[90%] sm:py-8 xs:py-8 flex justify-center items-center flex-row md:flex-row sm:flex-col xs:flex-col gap-4 ">
      <img
            src={AboutPic}
            className="w-[100%] h-[100%] "
          />
      </div>
      <div className=" w-full  mt-8 p-0 md:p-0 sm:p-0 xs:p-0 backdrop-blur-sm ">
        <h3 className=" primary text-5xl md:text-5xl sm:text-4xl xs:text-4xl font-black  text-left md:text-left sm:text-left xs:text-left mb-4">
          About Us
        </h3>
        <hr className="w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] weight-900 secondary " />

        <p className="text-lg mt-4 md:mt-4 sm:mt-0 xs:mt-0  primary text-left">
          Welcome to our tech company! We are a dynamic and innovative team of
          tech enthusiasts dedicated to providing cutting-edge solutions for
          businesses seeking to leverage technology for growth and success. With
          our expertise in various technological domains, we aim to empower
          businesses with digital transformation, software development, and IT
          consulting services.
        </p>
      </div>{" "}
      <div className=" w-full  mt-8 p-0 md:p-0 sm:p-0 xs:p-0 backdrop-blur-sm ">
        <h3 className="  primary text-5xl md:text-5xl sm:text-4xl xs:text-4xl font-black  text-left md:text-left sm:text-left xs:text-left mb-4">
          Mission
        </h3>
        <hr className="w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] weight-900 secondary " />

        <p className="text-lg mt-4 md:mt-4 sm:mt-0 xs:mt-0  primary text-left">
          At our tech company, our mission is to deliver exceptional
          technological solutions that drive business growth, improve
          operational efficiency, and enhance customer experiences. We strive to
          stay at the forefront of emerging technologies, ensuring our clients
          benefit from the latest advancements in the digital landscape.
        </p>
      </div>
      <div className=" w-full  mt-8 p-0 md:p-0 sm:p-0 xs:p-0 backdrop-blur-sm ">
        <h3 className=" primary text-5xl md:text-5xl sm:text-4xl xs:text-4xl font-black mb-4">
          Services
        </h3>
        <hr className="w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] weight-900 secondary " />

        <div className=" w-full lg:w-full md:w-[80%]    flex justify-center  items-start flex-1 flex-col    ">
          <div className="p-2  Transition   rounded flex flex-col justify-left items-left ">
            <div className="flex flex-row justify-left bg-transparent items-center ">
              <Check className="important  primary rounded-full White-Border  whiteshadow text-2xl " />
              <h3 className="secondary text-xl pl-1 font-bolder ">
                <span className=" primary "> Website Development</span>
              </h3>
            </div>
          </div>
          <div className="p-2  Transition  rounded  flex flex-col justify-left items-left">
            <div className="flex flex-row justify-left items-center">
              <Check className="important  primary rounded-full White-Border text-2xl " />
              <h3 className="secondary text-xl pl-1 font-bolder ">
                
                <span span className=" primary ">
                Search Engine Optimization
                </span>
              </h3>
            </div>
          </div>

          <div className="p-2  Transition  rounded  flex flex-col justify-left items-left">
            <div className="flex flex-row justify-left items-center">
              <Check className="important  primary rounded-full White-Border text-2xl " />
              <h3 className="secondary text-xl pl-1 font-bolder ">
                 
                <span span className=" primary ">
                Graphic Design
                </span>
              </h3>
            </div>
          </div>

          <div className="p-2  Transition  rounded  flex flex-col justify-left items-left">
            <div className="flex flex-row justify-left items-center">
              <Check className="important  primary rounded-full White-Border text-2 xl " />
              <h3 className="secondary text-xl pl-1 font-bolder ">
                 
                <span span className=" primary ">
                Social Media Marketing
                </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default LandingFace;

import React from "react";
import { AccessAlarm, ThreeDRotation } from "@mui/icons-material";
import Blockers from "./Blockers";

function Features() {
  return (
    <div className="px-16 pb-8 md:px-16 BackBlocksSeo sm:px-4 xs:px-4  GrayMorphism pt-8 ">
      <div className="flex flex-row justify-center md:justify-center sm:justify-left xs:justify-left items-center pt-8 ">
        <hr className="w-12 text-2xl " />
        <h3 className="text-xl px-4 tracking-wider secondary">
          Unique Graphics
        </h3>

        <hr className="w-12 text-2xl" />
      </div>
      <h2 className="tracking-wider text-center md:text-center sm:text-left xs:text-left  text-4xl mt-4 font-black primary">
        Services we offer
      </h2>
      <div>
        <Blockers />
      </div>
    </div>
  );
}

export default Features;

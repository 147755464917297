import React from "react";
import Landing from "./components/LandingFace";
import Experty from "./components/Experty";
import Blockers from "./components/Blockers";
import Features from "./components/Features";
import Whyus from "./components/WhyUs";
import Whatweare from "./components/Whatweare";
import PicSpacer from "../../Home/Components/PicSpacer";
import Ready from "./components/Ready";
import Portfolio from "./components/portfoliio";
import { Helmet } from "react-helmet";

function index() {
  return (
    <div>
      <Helmet>
        <title>Graphic Design</title>
        <meta
          name="description"
          content="Our team of creative designers specializes in providing high-quality graphic design services to help businesses and individuals make a lasting impression."
        />
        <link rel="canonical" href="https://fnevol.com/GraphicDesign" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="text" />
        <meta
          property="og:title"
          content="Graphic Design : User Experience "
        />
        <meta
          property="og:description"
          content="Our team of skilled graphic designers has a keen eye for aesthetics and a passion for innovation. We strive for excellence in every design we create, ensuring that your brand stands out from the competition."
        />
        <meta property="og:url" content="https://fnevol.com/GraphicDesign" />
        {/* End Facebook tags */}
      </Helmet>
 
      <Landing />
      <Whatweare />
      <Whyus />
      <Features />
      {/* <Portfolio /> */}
      <Ready />
      <PicSpacer />
    </div>
  );
}

export default index;

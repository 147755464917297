import {
  AddBusiness,
  Animation,
  ArrowRightAlt,
  Business,
  ComputerTwoTone,
  GraphicEq,
  PendingActionsSharp,
  PhoneAndroid,
  SearchSharp,
  SettingsSuggest,
  SupportSharp,
} from "@mui/icons-material";
import React from "react";

function Blockers() {
  return (
    <div className="">
      <div className="grid grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 ">
        <div></div>
        <div>
          <div className="flex flex-row justify-center items-center pt-8 ">
            <hr className="w-12 text-2xl " />
            <h3 className="text-xl px-4 tracking-wider secondary">
              Unique Designs
            </h3>

            <hr className="w-12 text-2xl" />
          </div>
          <h2 className="tracking-wider text-center text-4xl mt-4 font-black primary">
            Amazing features
          </h2>
        </div>
      </div>

      <div className=" sm:gap-2 xs:gap-2 grid grid-rows-2 md:grid-rows-2 sm:grid-rows-none xs:grid-rows-none  grid-cols-none md:grid-cols-none sm:grid-cols-2 xs:grid-cols-2 grid-flow-col md:grid-flow-col sm:grid-flow-row xs:grid-flow-row  place-content-end md:place-content-end sm:place-content-center xs:place-content-center mt-12">
        <div className="bg-[#222] pb-4  Transition  w-20 md:h-44 h-80 sm:h-40 xs:h-40 md:w-48 sm:w-full xs:w-full rounded-lg flex flex-col justify-center items-center">
          <PhoneAndroid className="secondary text-7xl important Border p-2 rounded-full" />
          <h3 className="text-white text-[17px] text-center  w-[80%]">
            Responsive Design
          </h3>
        </div>
        <div className="bg-[#222] pb-4  Transition   w-20 md:h-44 h-80 ssm:h-40 xs:h-40 6 md:w-48 sm:w-full xs:w-full rounded-lg flex flex-col justify-center items-center">
          <SettingsSuggest className="secondary text-7xl important p-2 Border rounded-full" />
          <h3 className="text-white text-[17px] text-center  w-[80%]">
            Powerful Customization
          </h3>
        </div>
        <div className="bg-[#222] pb-4  Transition   w-20 md:h-44 h-80 ssm:h-40 xs:h-40 6 md:w-48 sm:w-full xs:w-full rounded-lg flex flex-col justify-center items-center">
          <Animation className="secondary text-7xl important Border p-2 rounded-full" />
          <h3 className="text-white text-[17px] text-center  w-[80%]">
            Awesome & Modern Animations
          </h3>
        </div>
        <div className="bg-[#222] pb-4  Transition   w-20 md:h-44 h-80 ssm:h-40 xs:h-40 6 md:w-48 sm:w-full xs:w-full rounded-lg flex flex-col justify-center items-center">
          <GraphicEq className="secondary text-7xl important Border p-2 rounded-full" />
          <h3 className="text-white text-[17px] text-center  w-[80%]">
            SEO Friendly Coding
          </h3>
        </div>
        <div className="bg-[#222] pb-4  Transition   w-20 md:h-44 h-80 ssm:h-40 xs:h-40 6 md:w-48 sm:w-full xs:w-full rounded-lg flex flex-col justify-center items-center">
          <Business className="secondary text-7xl important Border p-2 rounded-full" />
          <h3 className="text-white text-[17px] text-center  w-[80%]">
            E-commerce Solutions
          </h3>
        </div>
        <div className="bg-[#222] pb-4  Transition   w-20 md:h-44 h-80 ssm:h-40 xs:h-40 6 md:w-48 sm:w-full xs:w-full rounded-lg flex flex-col justify-center items-center">
          <SupportSharp className="secondary text-7xl important Border p-2 rounded-full" />
          <h3 className="text-white text-[17px] text-center  w-[80%]">
            Website Maintenance & Support
          </h3>
        </div>
      </div>
    </div>
  );
}

export default Blockers;

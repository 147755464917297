import React from "react";
import { BrowserRouter as Router, Route, Link, Routes, ScrollRestoration } from "react-router-dom";
import Navbar from "./House/Parents/Navbar/Tnav";
import Home from "./House/Components/Home/Home";
import Footer from "./House/Parents/Footer/Footer";
import Graphics from "./House/Components/Services/Graphics/index"
import SEO from "./House/Components/Services/SEO/index"
import WebDesign from "./House/Components/Services/WebDesign/index"
import SocialMedia from "./House/Components/Services/SocialMedia/index"
import SoftwareDesign from "./House/Components/Services/SoftwareDesign/index"
import BulkSMS from "./House/Components/Services/BulkSMS/index"
import Testimonials from "./House/Components/Home/Components/Testimonials"
import About from "./House/Components/Us/About/About"
import Contact from "./House/Components/Us/Contact/Contact"

function App() {
  return (
    <div>

      <Navbar />
      <Routes>

        <Route index element={<Home />} />
        <Route path="Bulksms" element={<BulkSMS />} />
        <Route path="GraphicDesign" element={<Graphics />} />
        <Route path="SEO" element={<SEO />} />
        <Route path="WebDesign" element={<WebDesign />} />
        <Route path="SocialMedia" element={<SocialMedia />} />
        <Route path="SoftwareDesign" element={<SoftwareDesign />} />
        <Route path="About" element={<About />} />
        <Route path="Contact" element={<Contact />} />
      </Routes>
      <Testimonials/>
      <Footer />
    </div>
  );
}

export default App;

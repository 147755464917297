import React from "react";
import Slide from "../../../../Assets/Images/Graphic/GraphicCreative.png";

import { Check, FactCheck } from "@mui/icons-material";

function Whatwedo() {
  return (
    <div className="my-28 lg:my-28  md:my-28  sm:my-20  xs:my-20  px-16 md:px-16 sm:px-4 xs:px-4 flex gap-4 flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col   ">
      <div className="basis-1/2  pr-8 md:pr-0 sm:pr-0 xs:pr-0 ">
        <h3 className="primary text-5xl md:text-5xl sm:text-4xl xs:text-4xl font-black mb-4">
          Why Choose Us
        </h3>
        <hr className="w-full weight-900 secondary " />

        <div className="gap-4 w-full lg:w-full md:w-[80%]   sm:py-8 xs:py-8 flex justify-center  items-start flex-1 flex-col    ">
          <div className="p-4 bg-[#333] Transition  rounded flex flex-col justify-left items-left ">
            <div className="flex flex-row justify-left items-center ">
              <Check className="important text-white rounded-full White-Border  whiteshadow text-4xl " />
              <h3 className="secondary text-3xl pl-1 font-bolder ">
                Creative <span className="text-white ">Excellence</span>
              </h3>
            </div>
            <p className="Whitext">
              Our team of skilled graphic designers has a keen eye for
              aesthetics and a passion for innovation. We strive for excellence
              in every design we create, ensuring that your brand stands out
              from the competition.
            </p>
          </div>
          <div className="p-4 bg-[#333] Transition  rounded  flex flex-col justify-left items-left">
            <div className="flex flex-row justify-left items-center">
              <Check className="important text-white rounded-full White-Border text-4xl " />
              <h3 className="secondary text-3xl pl-1 font-bolder ">
                Tailored{" "}
                <span span className="text-white ">
                  Solutions
                </span>
              </h3>
            </div>
            <p className="Whitext">
              We understand that your design needs are unique. That's why we
              take a personalized approach to every project, tailoring our
              services to meet your specific requirements and objectives.
            </p>
          </div>
          <div className="p-4 bg-[#333] Transition  rounded  flex flex-col justify-left items-left">
            <div className="flex flex-row justify-left items-center">
              <Check className="important text-white rounded-full White-Border text-4xl " />
              <h3 className="secondary text-3xl pl-1 font-bolder ">
                Timely
                <span span className="text-white ">
                   Delivery
                </span>{" "}
              </h3>
            </div>
            <p className="Whitext">
              We value your time as much as you do. We are committed to
              delivering high-quality designs within the agreed-upon deadlines,
              ensuring a smooth and efficient design process.
            </p>
          </div>
        </div>
      </div>
      <div className="basis-1/2 w-[100%] md:w-[50%] flex h-full  justify-center items-center  relative">
        <img
          src={Slide}
          className="w-[80%] md:w-[80%] sm:w-[100%] xs:w-[100%] md:w-[100%] contain  h-full md:h-full sm:h-[400px] xs:h-[400px]  "
          alt=""
        />
      </div>
    </div>
  );
}

export default Whatwedo;

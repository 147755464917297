import React from "react";
import Landing from "./components/LandingFace";
import Experty from "./components/Experty";
import Blockers from "./components/Blockers";
import Features from "./components/Features";
import Whyus from "./components/WhyUs";
import Whatweare from "./components/Whatweare";
import PicSpacer from "../../Home/Components/PicSpacer";
import Ready from "./components/Ready";
import Portfolio from "./components/portfoliio";
import { Helmet } from "react-helmet";

function index() {
  return (
    <div>
      <Helmet>
        <title>social media management </title>
        <meta
          name="description"
          content="Welcome to our Social Media Marketing site, Unlock the full potential of social media and skyrocket your brand's online presence with Fnevol. We are a leading social media marketing agency that specializes in helping businesses like yours thrive in the digital landscape. "
        />
        <link rel="canonical" href="https://fnevol.com/SocialMedia" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="text" />
        <meta
          property="og:title"
          content="social media management : fast onlibe : fast search : business branding"
        />
        <meta
          property="og:description"
          content="Whether you're a small startup or an established enterprise, our expert team is here to deliver results that exceed your expectations. We believe in pushing creative boundaries to create exceptional content that stands out. Our team of creative professionals will bring fresh ideas and innovative strategies to elevate your brand above the competition."
        />
        <meta property="og:url" content="https://fnevol.com/SocialMedia" />
 
        {/* End Facebook tags */}
      </Helmet>
 
      <Landing />
      <Whatweare />
      <Whyus />
      <Features />
      {/* <Portfolio /> */}
      <Ready />
      <PicSpacer />
    </div>
  );
}

export default index;

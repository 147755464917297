import { Close, Menu } from "@mui/icons-material";
import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Images/Home/black.png";
import Fav from "../../Assets/Images/Home/Logo2.png";
import Logo1 from "../../Assets/Images/Home/cleanlogowhite.png";

function Tnav() {
  const [shift, setShift] = useState(!true);
  const [flag, setFlag] = useState(setShift);
  const Clicker = (e) => {
    setFlag(!flag);
  };

  // Dropdown Menu
  const [dropper, setDropper] = useState(!true);
  const [flager, setFlager] = useState(setDropper);
  const TheDropper = (e) => {
    setFlager(!flager);
  };

  //   Animation
  const item = {
    exit: {
      opacity: 0,
      height: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.3,
        delay: 0.9,
      },
    },
  };

  // ChangeColor

  const [color, setColor] = useState(true);

  const changeColor = () => {
    //scroll points go up as the page is scrolled down
    if (window.scrollY <= 100) {
      setColor(true);
      // console.log("true");
      // console.log(window.scrollY);
    } else {
      setColor(false);
      // console.log("false");
      // console.log(window.scrollY);
    }
  };
  // every part of this function is necessary.
  //It won't work if you emit the ", true" parameter
  window.addEventListener("scroll", changeColor, true);

  return (
    <div
      className={
        color
          ? "Transition flex flex-col   w-full h-24 flex justify-between fixed z-50 transparent primary"
          : "Transition bg-[#333] flex flex-col   w-full h-24 flex justify-between fixed z-50"
      }
    >
      <header className=" flex flex-row justify-between items-center  pt-8 px-16 md:px-16 sm:px-4 xs:px-4">
        <div className="flex-1">
          {color ? (
            <img src={Logo} className="h-12 w-36 Transition" />
          ) : (
            <img src={Fav} className="Transition-Fast h-12 w-14" />
          )}
        </div>
        <div className="w-full flex-1 flex flex-row justify-end items-center gap-4  ">
         <Link to="/Contact">
          <button className="block md:block xs:hidden sm:hidden text-xl w-36 h-12 shader rounded-full border text-white">
            Contact Us
          </button></Link>
          <div
            onClick={Clicker}
            className="flex flex-row items-center text-white p-2 rounded-full shader"
          >
            {!flag ? (
              <Menu className=" Transition  text-white MenuIcon  z-60" />
            ) : (
              <Close className="hidden Transition  text-white MenuIcon  z-60" />
            )}
          </div>
        </div>
      </header>
      <AnimatePresence>
        <div
          className={
            !flag
              ? "Transition hidden"
              : "leading-3 flex mt-0 md:mt-0 sm:-mt-16 xs:-mt-16 flex-col items-start Transition uppercase w-full flex flex-col gap-4 h-[100vh] md:h-[100vh] xs:h-[105vh] sm:h-[105vh] absolute text-white blocks m-0 p-0 py-24 px-16 md:px-16 sm:px-4 xs:px-4 bg-[#333]  w-[40%]  md:w-[40%] xs:w-[100%] sm:w-[100%]"
          }
          variants={item}
          initials={{ height: 0, opacity: 0 }}
          animate={{ height: "100vh", opacity: 1 }}
          transition={{ duration: 0.7 }}
          exit={item.exit}
        >
          <div className="w-full flex flex-row justify-between items-center">
            <img src={Fav} className="h-12 w-14" />
            <div
              onClick={Clicker}
              className="flex flex-row items-start text-white "
            >
              {!flag ? (
                <Menu className="hidden  Transition ml-8 text-white MenuIcon pr-2 z-60" />
              ) : (
                <Close className="Transition ml-8 text-white MenuIcon pr-2 z-60" />
              )}
              <hr className="w-full text-white " />
            </div>
          </div>

          <Link to="" onClick={Clicker} className="pt-4">
            HOME
          </Link>

          <h3 className="Whitext mt-4">Services</h3>
          <hr className="w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] weight-900 secondary " />

          <Link
            to="/WebDesign"
            onClick={Clicker}
            className="Whitext-F font-lg md:font-lg xs:font-sm sm:font-sm"
          >
            WEBSITE DESIGN
          </Link>

          <Link
            to="/GraphicDesign"
            onClick={Clicker}
            className="Whitext-F font-lg md:font-lg xs:font-sm sm:font-sm"
          >
            GRAPHIC DESIGN
          </Link>

          <Link
            to="/SocialMedia"
            onClick={Clicker}
            className="Whitext-F font-lg md:font-lg xs:font-sm sm:font-sm"
          >
            SOCIAL MEDIA MANAGEMENT
          </Link>

          <Link
            to="/SEO"
            onClick={Clicker}
            className="Whitext-F font-lg md:font-lg xs:font-sm sm:font-sm"
          >
            SEARCH ENGINE OPTIMIZATION
          </Link>

          <h3 className="Whitext mt-4">Company</h3>
          <hr className="w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] weight-900 secondary " />
          <Link
            to="/About"
            onClick={Clicker}
            className="Whitext-F font-lg md:font-lg xs:font-sm sm:font-sm"
          >
            {" "}
            ABOUT US
          </Link>
          <Link
            to="/Contact"
            onClick={Clicker}
            className="Whitext-F font-lg md:font-lg xs:font-sm sm:font-sm"
          >
            {" "}
            CONTACT US
          </Link>
          <Link to="/Contact">
            <button
              onClick={Clicker}
              className="py-2 hidden md:hidden xs:block sm:block text-xl w-36 h-12 PrimaryB  rounded-full border text-white"
            >
              Get A Quote
            </button>
          </Link>
        </div>
      </AnimatePresence>
    </div>
  );
}

export default Tnav;

import React from "react";
import LandingFace from "./Components/LandingFace";
import Whatwedo from "./Components/Whatwedo";
import Whoweare from "./Components/Whoweare";
import Blocks from "./Components/Blocks";
import PicSpacer from "./Components/PicSpacer";
import TopBox from "./Components/TopBox";
import Testimonials from "./Components/Testimonials";
import Ready from "./Components/Ready";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div>
      <Helmet>
        <title>Home</title>
        <meta
          name="description"
          content="We specialize in providing top-notch solutions to businesses of all sizes. We are focused on web design services, software development services, graphics designs, social media marketing and bulk sms services."
        />
        <link rel="canonical" href="/" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="text" />
        <meta property="og:title" content="Technology Growing Business : Website Design :Graphic Design : Socal Media Branding" />
        <meta property="og:description" content="We believe that every business is unique and deserves a website that reflects its individuality. Our team of skilled designers works closely with you to understand your specific needs and preferences, creating customized designs that are both visually appealing and functional." />
        <meta property="og:url" content="https://fnevol.com/" />
        {/* End Facebook tags */}
      
      </Helmet>
      <LandingFace />
      {/* <TopBox/>  */}
      <Whoweare />
      <Blocks />
      <Whatwedo />
      <Ready />
      <PicSpacer />
    </div>
  );
}

export default Home;

import React from "react";
import LetsGo from "../../../Assets/Images/Home/LetsGo.png"
import { Link } from "react-router-dom";

function PicSpacer() {
  return (
    <div className="PicSpacerBG  h-[400px]  ">
      <div className=" w-full px-16 md:px-16 sm:px-4 xs:px-4  grid grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 h-[400px] sm:h-full xs:h-full shader gap-4 ">
        <div className="flex flex-col justify-center items-right text-right h-full w-full  items-end ">
          <img className="w-full "  src={LetsGo} />
        </div>
        <div className=" flex flex-col justify-center h-full w-full  items-start flex-col ">
          <h2 className="tracking-wider uppercase text-white text-left w-[70%]     md:w-[70%] xs:w-[100%] sm: w-[100% text-4xl mt-4 font-black ">
            We prioritize <br />
            open Talk
          </h2>
          <p className="text-left text-white text-md w-[70%]    md:w-[70%] xs:w-[100%] sm: w-[100%">
            & transparency to build strong and long-lasting partnerships. Your
            success is our success.
          </p>
          {/* Contact US Button */}
         <Link to="/Contact">
          <button className="py-2 mt-4  text-xl w-36 h-12 rounded-full border text-white border-orange-500">
            Lets' Talk
          </button>
         </Link>
        </div>
      </div>
    </div>
  );
}

export default PicSpacer;

import React from "react";
import Slide from "../../../../Assets/Images/WebDesign/WhyUsPng.png";

import { Check, FactCheck } from "@mui/icons-material";

function Whatwedo() {
  return (
    <div className="my-28 lg:my-28  md:my-28  sm:my-20  xs:my-20  px-16 md:px-16 sm:px-4 xs:px-4 flex gap-4 flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col   ">
      <div className="flex-1 pr-8 md:pr-0 sm:pr-0 xs:pr-0 ">
        <h3 className="primary text-5xl md:text-5xl sm:text-4xl xs:text-4xl font-black mb-4 md:mb-4 xs:mb-0 sm:mb-0">
          Why Choose Us
        </h3>
        <hr className="w-full weight-900 secondary " />

        <div className="gap-4 w-full lg:w-full md:w-[80%]   sm:py-8 xs:py-8 flex justify-center  items-start flex-1 flex-col    ">
          <div className="p-4 bg-[#333] Transition  rounded flex flex-col justify-left items-left ">
            <div className="flex flex-row justify-left items-center ">
              <Check className="important text-white rounded-full White-Border  whiteshadow text-4xl " />
              <h3 className="secondary text-3xl pl-1 font-bolder ">
                Best <span className="text-white ">Web Tech</span>
              </h3>
            </div>
            <p className="Whitext">
              We believe that every business is unique and deserves a website
              that reflects its individuality. Our team of skilled designers
              works closely with you to understand your specific needs and
              preferences, creating customized designs that are both visually
              appealing and functional.
            </p>
          </div>
          <div className="p-4 bg-[#333] Transition  rounded  flex flex-col justify-left items-left">
            <div className="flex flex-row justify-left items-center">
              <Check className="important text-white rounded-full White-Border text-4xl " />
              <h3 className="secondary text-3xl pl-1 font-bolder ">
                Best{" "}
                <span span className="text-white ">
                  Customer Service
                </span>
              </h3>
            </div>
            <p className="Whitext">
              we are dedicated to providing personalized attention, clear
              communication, a collaborative approach, timely delivery,
              post-launch support, and a customer satisfaction guarantee.
            </p>
          </div>
          <div className="p-4 bg-[#333] Transition  rounded  flex flex-col justify-left items-left">
            <div className="flex flex-row justify-left items-center">
              <Check className="important text-white rounded-full White-Border text-4xl " />
              <h3 className="secondary text-3xl pl-1 font-bolder ">
                Best{" "}
                <span span className="text-white ">
                  Competitive Pricing
                </span>{" "}
              </h3>
            </div>
            <p className="Whitext">
              We offer competitive pricing without compromising on quality. Our
              web design packages are tailored to fit your budget and needs,
              ensuring that you get the best value for your money.
            </p>
          </div>
        </div>
      </div>
      <div className="basis-1/2 w-[100%] md:w-[50%] flex h-full  justify-center items-center mt-24 md:mt-24 sm:mt-0 xs:mt-0 relative">
        <img
          src={Slide}
          className="w-[80%] md:w-[80%] sm:w-[100%] xs:w-[100%] md:w-[100%] contain  h-[400px] md:h-[400px] sm:h-[400px] xs:h-[400px]  "
          alt=""
        />
      </div>
    </div>
  );
}

export default Whatwedo;

import React from "react";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import SEO from "../../../../Assets/Images/SEO/SEO.png";

function LandingFace() {
  return (
    <div className="HomePageLandingSeo ">
      <div className=" flex flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col justify-center items-center h-[100vh]   md:h-[100vh] sm:h-[100vh] xs:h-[100vh] w-full  zindex-8">
        <div className=" basis-1/2 md:basis-1/2 sm:basis-3/4 xs:basis-3/4 flex justify-cemter items-end p-8 md:p-8 sm:p-4 xs:p-4">
          <img
            src={SEO}
            className="w-[85%]  lg:w-[85%] md:w-[85%] xs:w-[100%] sm:w-[100%] h-[60%] md:h-[60%] sm:h-[80%] xs:h-[80%]  "
          />
        </div>
        <div className=" mt-20 sm:mt-0 xs:mt-0 basis-1/2 md:basis-1/2 sm:basis-1/4 xs:basis-1/4   w-[70%] md:w-[90%] xs:w-[90%] sm:w-[90%] backdrop-blur-sm p-8 md:p-8 sm:p-4 xs:p-4 flex justify-center  flex-col items-start mt-[30vh] w-full h-[100vh] ">
          <h3 className="text-[#333] font-black text-[50px] md:text-5xl sm:text-3xl xs:text-3xl">
            Boost Your
            <br className="block md:block sm:hidden xs:hidden " />
            Online <br className="hidden md:hidden  sm:hidden xs:hidden " />
            Presence <br className="block md:hidden sm:hidden xs:hidden " />&
            Increase Visibility
          </h3>
          <p className="block md:block sm:hidden xs:hidden   w-[70%] md:w-[90%] xs:w-[90%] sm:w-[90%] backdrop-blur-sm text-[#111] leading-1  pt-8 sm:pt-4 xs:pt-4 text-xl md:text-xl sm:text-lg xs:text-lg ">
            Our SEO services are designed to improve your website's visibility
            in search engine results, driving organic traffic and boosting your
            online presence.
          </p>
          <button className="mt-8 sm:mt-4 xs:mt-4 Whiteshadow text-xl  Primary text-white w-40 text-center rounded-tr-full rounded-br-full rounded-bl-full h-12">
            Check More
          </button>

          {/* Blocks and icons */}
          <div className="mt-8 sm:mt-4 xs:mt-4  flex-row gap-3 flex md:flex sm:hidden xs:hidden    px-4 py-2 rounded-full whiteshadow">
            <div className="w-12  h-12 bg-white md:bg-white sm:bg-black xs:bg-black darkshadow flex justify-center items-center rounded-full">
              <Instagram className="important font-xl secondary" />
            </div>
            <div className="w-12 h-12 bg-white md:bg-white sm:bg-black  xs:bg-black darkshadow flex justify-center items-center rounded-full">
              <Facebook className="important font-xl secondary" />
            </div>
            <div className="w-12 h-12 bg-white md:bg-white darkshadow sm:bg-black xs:bg-black flex justify-center items-center rounded-full">
              <LinkedIn className="important font-xl secondary" />
            </div>
            <div className="w-12 h-12 bg-white md:bg-white darkshadow sm:bg-black xs:bg-black flex justify-center items-center rounded-full">
              <Twitter className="important font-xl secondary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingFace;

import React from "react";
import Landing from "./components/LandingFace";
import Experty from "./components/Experty";
import Blockers from "./components/Blockers";
import Features from "./components/Features";
import Websolutions from "./components/Websolutions";
import Whyus from "./components/WhyUs";
import Whatweare from "./components/Whatweare";
import PicSpacer from "./../../Home/Components/PicSpacer";
import Ready from "./components/Ready";
import Portfolio from "./components/portfoliio";
import { Helmet } from "react-helmet";

function index() {
  return (
    <div>
      <Helmet>
        <title>Website design : unique websites</title>
        <meta
          name="description"
          content=" We believe in delivering exceptional website design results for our clients. Our team of experts has years of experience in the industry and are dedicated to providing tailored solutions to meet your unique business website needs."
        />
        <link rel="canonical" href="https://fnevol.com/WebDesign "/>
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="text" />
        <meta
          property="og:title"
          content="Website design : unique websites : business website"
        />
        <meta
          property="og:description"
          content="We believe that every business is unique and deserves a website that reflects its individuality. Our team of skilled designers works closely with you to understand your specific needs and preferences, creating customized designs that are both visually appealing and functional."
        />
        <meta property="og:url" content="https://fnevol.com/WebDesign" />
        {/* End Facebook tags */}
      </Helmet>
      <Landing />
      <Whatweare />
      <Whyus />
      <Features />
      <Websolutions />
      {/* <Portfolio/> */}
      <Ready />
      <PicSpacer />
    </div>
  );
}

export default index;

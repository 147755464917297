import React from "react";
import WebDev from "../../../Assets/SVGz/Webdev.svg";
import Software from "../../../Assets/SVGz/Software.svg";
import SocialMedia from "../../../Assets/SVGz/Socialmedia.svg";
import Graphics from "../../../Assets/SVGz/Graphicdesign.svg";
import SMS from "../../../Assets/SVGz/sms.svg";
import { Link } from "react-router-dom";
import { ArrowRight, RampRight } from "@mui/icons-material";

function Blocks() {
  return (
    <div className=" px-16 md:px-16 sm:px-4 xs:px-4  Back2 my-14 lg:my-14  md:my-14  sm:my-14  xs:my-14 ">
      <div className=" flex justify-center w-full flex-col  items-center md:items-left sm:items-left xs:items-left ">
        <h3 className="primary text-5xl md:text-5xl sm:text-4xl xs:text-4xl font-black w-full text-center md:text-center sm:text-left xs:text-left mb-4">
          Our Services
        </h3>
        <hr className="w-[50%] md:w-[50%] sm:w-[100%] xs:w-[100%] weight-900 secondary " />
        <p className="text-lg mt-4 md:mt-4 sm:mt-1 xs:mt-1 text-center lg:text-center md:text-center sm:text-left xs:text-left w-[70%] lg:w-[70%] md:w-[70%] sm:w-[100%] xs:w-[100%] primary">
          We specialize in providing top-notch solutions to businesses of all
          sizes. Our team of experts is dedicated to helping our clients
          streamline their operations and improve their bottom line.
        </p>
        <div className="mt-8 flex-1 gap-4   grid grid-cols-4 lg:grid-cols-4 md:grid-cols-4 Transition w-[80%] md:w-[80%] sm:w-[100%] xs:w-[100%] sm:grid-cols-1 xs:grid-cols-1 justify-items-center">
          <div className=" bg-[#f2f2f2] rounded-lg shadow-gray-300 shadow-lg hover:shadow-none flex-1 Transition justify-center md:justify-center sm:justify-between xs:justify-between items-center w-[80%] md:w-[80%] sm:w-[100%] xs:w-[100%]  grid grid-cols-1 md:grid-cols-1 sm:grid-cols-2 xs:grid-cols-2 p-4">
            <img
              fill="black"
              stroke="yellow"
              className=" h-28 w-28 "
              src={WebDev}
              alt=""
            />
            <div className="flex flex-col justify-center items-center text-center md:text-center mt-0 sm:text-left xs:text-left ">
              <h3 className="text-xl text-center leading-5 font-bold">
                Website<br/> Development
              </h3>
              <Link to="/WebDesign">
                <button className="py-2 mt-2 text-lg w-28 h-10 rounded-full border primary flex justify-center items-center  border-orange-500">
                  More
                  <ArrowRight />
                </button>
              </Link>
            </div>
          </div>
          <div className=" bg-[#f2f2f2] rounded-lg shadow-gray-300 shadow-lg hover:shadow-none flex-1 Transition justify-center  md:justify-center sm:justify-between xs:justify-between items-center w-[80%] md:w-[80%] sm:w-[100%] xs:w-[100%]   grid grid-cols-1 md:grid-cols-1 sm:grid-cols-2 xs:grid-cols-2 p-4">
            <img
              fill="black"
              stroke="yellow"
              className=" h-28 w-28 "
              src={Software}
              alt=""
            />
            <div className="flex flex-col justify-center md:justify-center items-center md:items-center xs:items-left sm:items-left text-center md:text-center mt-0 sm:text-left xs:text-left ">
              <h3 className="text-xl text-center  leading-5 font-bold">
                Search Engine Optimization
              </h3>
              <Link to="/SEO">
                <button className="py-2 mt-2 text-lg w-28 h-10 rounded-full border primary flex justify-center items-center  border-orange-500">
                  More
                  <ArrowRight />
                </button>
              </Link>
            </div>
          </div>
          <div className=" bg-[#f2f2f2] rounded-lg shadow-gray-300 shadow-lg hover:shadow-none flex-1 Transition justify-center items-center  md:justify-center sm:justify-between xs:justify-between w-[80%] md:w-[80%] sm:w-[100%] xs:w-[100%]   grid grid-cols-1 md:grid-cols-1 sm:grid-cols-2 xs:grid-cols-2 p-4">
            <img
              fill="black"
              stroke="yellow"
              className=" h-28 w-28 "
              src={Graphics}
              alt=""
            />
            <div className="flex flex-col justify-center items-center text-center md:text-center mt-0 sm:text-left xs:text-left ">
              <h3 className="text-xl text-center leading-5 font-bold">
                Graphic
                <br className="blocks md:blocks xs:hidden sm:hidden" /> Design
              </h3>
              <Link to="/GraphicDesign">
                <button className="py-2 mt-2 text-lg w-28 h-10 rounded-full border primary flex justify-center items-center  border-orange-500">
                  More
                  <ArrowRight />
                </button>
              </Link>
            </div>
          </div>
          <div className=" bg-[#f2f2f2] rounded-lg shadow-gray-300 shadow-lg hover:shadow-none flex-1 Transition justify-center  md:justify-center sm:justify-between xs:justify-between items-center w-[80%] md:w-[80%] sm:w-[100%] xs:w-[100%]  grid grid-cols-1 md:grid-cols-1 sm:grid-cols-2 xs:grid-cols-2 p-4">
            <img
              fill="black"
              stroke="yellow"
              className=" h-28 w-28 "
              src={SocialMedia}
              alt=""
            />
            <div className="flex flex-col justify-center items-center text-center md:text-center mt-0 sm:text-left xs:text-left ">
              <h3 className="text-xl text-center  leading-5 font-bold">
                Social Media Management
              </h3>
              <Link to="/SocialMedia">
                <button className="py-2 mt-2 text-lg w-28 h-10 rounded-full border primary flex justify-center items-center  border-orange-500">
                  More
                  <ArrowRight />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blocks;

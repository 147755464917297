import React from 'react'
import LandingFace from './LandingFace'

function About() {
  return (
    <div>
        <LandingFace/>
    </div>
  )
}

export default About